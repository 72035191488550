import { useCallback, useState } from "react";
import { Chip, IconButton, Portal, Snackbar } from "react-native-paper";

import { useAppTheme } from "../../theme";

type HiddenWarningProps = {
  hiddenCount: number,
  subjectDisplayName?: string
};

const message = (hiddenCount: number, subjectDisplayName: string = "subjects") => `
  ${hiddenCount} ${subjectDisplayName}
   are not shown on the map. Filter the list for better results.
  `;

const HiddenWarning = ({ hiddenCount, subjectDisplayName }: HiddenWarningProps) => {
  const [showSnack, setShowSnack] = useState(false);
  const theme = useAppTheme();

  const handlePress = useCallback(() => {
    setShowSnack(true);
  }, []);

  const handleDismiss = useCallback(() => {
    setShowSnack(false);
  }, []);

  return (
    <>
      {hiddenCount && (
        <IconButton
          icon="alert"
          size={30}
          onPress={handlePress}
          iconColor={theme.colors.error}
        />
      )}
      <Portal>
        <Snackbar
          visible={showSnack}
          onDismiss={handleDismiss}
          action={{
            label: "Dismiss",
            onPress: handleDismiss,
          }}
        >
          {message(hiddenCount, subjectDisplayName)}
        </Snackbar>
      </Portal>
    </>
  );
};

export const HiddenWarningBanner = ({
  hiddenCount,
  subjectDisplayName,
}: HiddenWarningProps) => {
  if (!hiddenCount) {
    return null;
  }

  return (
    <Chip icon="alert" style={{ display: "flex", alignItems: "center" }}>
      {message(hiddenCount, subjectDisplayName)}
    </Chip>
  );
};

export default HiddenWarning;
