import { useCallback, useState, FC, ReactNode, ComponentProps } from "react";
import { Pressable, useWindowDimensions, View } from "react-native";
import { Button, Dialog, IconButton, Portal, Text } from "react-native-paper";

import { stylesheet } from "./styles";
import cancelNotify from "../../../lib/cancelNotify";

export type ActionItemProps = {
  icon: string;
  onPress?: () => void;
  text?: string;
  mode?: ComponentProps<typeof IconButton>["mode"];
  children?: ReactNode;
  confirmMessage?: string;
};

const ActionItem: FC<ActionItemProps> = ({
  icon,
  onPress,
  text = "",
  mode = "outlined",
  children = text,
  confirmMessage,
}) => {

  const windowDimensions = useWindowDimensions();

  const [dialogVisible, setDialogVisible] = useState(false);

  const handlePress = useCallback(() => {
    if (confirmMessage) {
      setDialogVisible(true);
    } else {
      onPress?.();
    }
  }, [confirmMessage, onPress]);

  const handleConfirm = useCallback(() => {
    setDialogVisible(false);
    onPress?.();
  }, [onPress]);

  const cancelConfirmation = cancelNotify();

  const cancelAlert = useCallback(() => {
    cancelConfirmation("Action Canceled");
    setDialogVisible(false);
  }, [cancelConfirmation]);

  return (
    <View style={stylesheet.actionItem}>
      <IconButton mode={mode} icon={icon} onPress={handlePress} />
      <Pressable onPress={handlePress}>
        <Text style={stylesheet.actionItemText}>{children}</Text>
      </Pressable>
      {confirmMessage && (
        <Portal>
          <Dialog
            visible={dialogVisible}
            onDismiss={() => setDialogVisible(false)}
            style={{
              maxWidth: windowDimensions.width > 600 ? "30%" : "90%",
              alignSelf: "center"
            }}
          >
            <Dialog.Title>Confirm Action</Dialog.Title>
            <Dialog.Content>
              <Text>{confirmMessage}</Text>
            </Dialog.Content>
            <Dialog.Actions>
              <Button onPress={cancelAlert}>Cancel</Button>
              <Button onPress={handleConfirm}>OK</Button>
            </Dialog.Actions>
          </Dialog>
        </Portal>
      )}
    </View>
  );
};

export default ActionItem;
